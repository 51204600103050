<template>
    <v-container>
        <v-row>
            <!-- 分类树形列表展示 -->
            <v-col cols="12" md="4">
                <v-btn color="primary" @click="addNewCategory" class="mb-4">
                    新增分类
                </v-btn>
                <v-treeview
                    :items="categoryTree"
                    item-key="id"
                    open-on-click
                    :open.sync="openItems"
                >
                    <template v-slot:label="{ item }">
                        <div style="display: flex; align-items: center;">
                            <span>{{ item.name }}</span>
                            <v-spacer></v-spacer>
                            <!-- 点击铅笔图标时加载数据到表单 -->
                            <v-btn icon small @click.stop="selectCategory(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon small @click.stop="deleteCategory(item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-treeview>
            </v-col>

            <!-- 右侧：新增/编辑分类表单 -->
            <v-col cols="12" md="8">
                <v-form ref="form" v-model="isFormValid">
                    <v-text-field v-model="categoryForm.name" label="分类名称" required></v-text-field>
                    <v-select
                        v-model="categoryForm.parentId"
                        :items="parentOptions"
                        item-text="name"
                        item-value="id"
                        label="父分类"
                        clearable
                    ></v-select>
                    <v-btn color="primary" @click="saveCategory">保存</v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import service from '@/utils/request';

export default {
    data() {
        return {
            categories: [],
            categoryForm: { id: null, name: '', parentId: null },
            isFormValid: false,
            parentOptions: [],
            categoryTree: [], // 树形结构数据
            openItems: [] // 打开的节点 ID 列表
        };
    },
    created() {
        this.loadCategories();
    },
    methods: {
        loadCategories() {
            service.get('/wikiapi/categories')
                .then(response => {
                    this.categories = response.data;
                    this.parentOptions = response.data;
                    this.categoryTree = this.buildTree(this.categories);

                    // 默认展开所有节点，将所有分类的 ID 添加到 openItems 中
                    this.openItems = this.categories.map(category => category.id);
                })
                .catch(error => console.error("Error loading categories:", error));
        },
        // 构建树形结构
        buildTree(categories) {
            const map = {};
            categories.forEach(cat => (map[cat.id] = { ...cat, children: [] }));
            const tree = [];
            categories.forEach(cat => {
                if (cat.parentCategory) {
                    map[cat.parentCategory.id].children.push(map[cat.id]);
                } else {
                    tree.push(map[cat.id]);
                }
            });
            return tree;
        },
        selectCategory(category) {
            // 点击铅笔图标时加载数据到表单
            const parentId = category.parentCategory ? category.parentCategory.id : null;
            this.categoryForm = { id: category.id, name: category.name, parentId: parentId };
        },
        addNewCategory() {
            this.categoryForm = { id: null, name: '', parentId: null };
        },
        saveCategory() {
            const apiUrl = this.categoryForm.id ? '/wikiapi/category' : '/wikiapi/category';
            const method = this.categoryForm.id ? 'put' : 'post';

            const payload = {
                ...this.categoryForm,
                parentCategory: this.categoryForm.parentId ? { id: this.categoryForm.parentId } : null
            };

            service({
                url: apiUrl,
                method,
                data: payload
            }).then(() => {
                this.loadCategories();
                this.addNewCategory();
            }).catch(error => console.error("Error saving category:", error));
        },
        deleteCategory(id) {
            service.delete('/wikiapi/category', { params: { id } })
                .then(() => this.loadCategories())
                .catch(error => console.error("Error deleting category:", error));
        }
    }
};
</script>

<style scoped>
/* 树形列表的基本样式 */
.v-treeview-node__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>